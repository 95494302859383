<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? $t('Update Expense') : $t('Add New Expense') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <app-branch-select v-model="formData.branch_id" />
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Expense Type')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.expense_type_id"
                      :label="`* ${$t('Expense Type')}`"
                      :items="expenseTypesList"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Amount')"
                    rules="required"
                  >
                    <v-currency-field
                      v-model="formData.amount"
                      :label="`* ${$t('Amount')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Note')"
                  >
                    <v-textarea
                      v-model="formData.note"
                      :label="$t('Note')"
                      outlined
                      dense
                      rows="3"
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <v-menu
                    ref="actionDate"
                    v-model="dateMenu"
                    transition="slide-y-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.action_date"
                        :label="$t('Date')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="formData.action_date"
                      no-title
                      @input="dateMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <app-submit-button :edit-mode="editMode" />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      dateMenu: false
    }
  },

  computed: {
    ...mapGetters(['expenseTypesList'])
  },


  methods: {
    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      if (this.editMode) {
        axios.put(`expense/${this.formData.id}/`, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('update-table-data')
        })
      } else {
        axios.post('expense/', this.formData).then(() => {
          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      }
    }
  },
}
</script>
